import { ClientT, HomeSettingsI } from '@/config/types'

import nbcuDtcHomeSettings from './nbcu-dtc'
import nowHomeSettings from './now'
import peacockHomeSettings from './peacock'
import showmaxHomeSettings from './showmax'
import skyEuropeHomeSettings from './sky-europe'
import skyEuropeAndPeacockHomeSettings from './sky-europe-and-peacock'
import skyshowtimeHomeSettings from './skyshowtime'

export const homeSettings: Record<ClientT, HomeSettingsI> = {
  peacock: peacockHomeSettings,
  skyshowtime: skyshowtimeHomeSettings,
  now: nowHomeSettings,
  'nbcu-dtc': nbcuDtcHomeSettings,
  'sky-europe-and-peacock': skyEuropeAndPeacockHomeSettings,
  'sky-europe': skyEuropeHomeSettings,
  showmax: showmaxHomeSettings,
}

export default homeSettings
