import { DurationInputArg1, DurationInputArg2 } from 'moment'
import {
  FINOPS_API_DOMAIN,
  DEFAULT_DATE,
  FEATURE_FLAGS,
} from '@/config/constants'

import { SPLIT_BY } from '@/config/formOptions'

import { FormI, MetricSettingsFnArgs } from '@/config/types'

import { GROUPS } from '@/config/metricsSettings/groups'
import { convivaStyleDataFormatters } from '@/config/metricsSettings/dataFormatterHelpers'

const commonFinOpsConfig = ({ featureFlags }: MetricSettingsFnArgs) => {
  const isFinopsEnabled = featureFlags[FEATURE_FLAGS.PLATFORM_FINOPS_DATA]

  const form: FormI[] = [
    {
      value: 'split-by',
      type: 'button-group',
      multi: false,
      options: [
        SPLIT_BY['proposition'],
        SPLIT_BY['vendor'],
        SPLIT_BY['environment'],
        SPLIT_BY['region'],
        SPLIT_BY['team'],
        SPLIT_BY['usage-family'],
        SPLIT_BY['service-name'],
      ],
    },
    // Rest of options will come from tags endpoint
  ]

  return {
    dataSource: 'FinOps',
    legacyGroup: 'finops',
    group: GROUPS.FINOPS,
    apiDomain: FINOPS_API_DOMAIN,
    supportsClient: true,
    hideInLegacyMetrics: true,
    hideInMetricsExplorer: !isFinopsEnabled,
    tags: {
      enabled: true,
      endpoint: '/tags',
    },
    form,
    formatter: convivaStyleDataFormatters,
    granularity: (): [DurationInputArg1, DurationInputArg2] => {
      return [1, 'day']
    },
    defaultConfig: {
      'split-by': 'vendor',
      proposition: ['all'],
      vendor: ['all'],
      environment: ['all'],
      region: ['all'],
      team: ['all'],
      'usage-family': ['all'],
      'service-name': ['all'],
      ...DEFAULT_DATE('peacock'),
    },
  }
}
export default commonFinOpsConfig
