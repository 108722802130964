import { HiOutlineCurrencyDollar, HiCurrencyDollar } from 'react-icons/hi'

import { MetricSettingsFn, MetricSettingsI } from '@/config/types'
import commonFinOpsConfig from './common/finOps'

const spend: MetricSettingsFn = (props): MetricSettingsI => {
  const finOpsConfig = commonFinOpsConfig(props)

  return {
    ...finOpsConfig,
    value: 'spend',
    label: 'Spend ($)',
    shortLabel: 'Spend ($)',
    dataKey: 'spend',
    annotationFlags: {
      peak: {
        enabled: true,
        logic: 'total',
      },
      min: {
        enabled: true,
        logic: 'total',
      },
    },
    defaultChartType: 'areaspline',
    chartTypes: ['spline', 'areaspline'],
    events: {
      linear: {
        enabled: true,
        order: 2,
        count: 10,
      },
      sle: {
        enabled: true,
        order: 1,
        count: 5,
      },
      vod: {
        enabled: false,
      },
      flags: {
        linear: {
          enabled: true,
        },
        sle: {
          enabled: true,
        },
        count: 10,
      },
    },
    icon: HiOutlineCurrencyDollar,
    solidIcon: HiCurrencyDollar,
    yAxisFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(value),
    valueFormat: (value: number): string =>
      new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(value),
    info: 'Spend reflects cloud service costs within a defined period. Data is refreshed daily, capturing data up until 3 days prior, with monthly adjustments on the 13th.',
    aggregationType: 'total',
    defaultConfig: {
      ...finOpsConfig.defaultConfig,
      metric: 'spend',
    },
  }
}

export default spend
