import { RouteObject } from 'react-router'

import appSettings from '@/config/appSettings'
import clientSettings from '@/config/clientSettings'
import minutesUniquesRealtimeSettings from '@/config/minutesUniquesRealtimeSettings'
import minutesUniquesSettings from '@/config/minutesUniquesSettings'
import navigation from '@/config/navigation'
import contactPageSettings from '@/config/pagesSettings/contactPageSettings'
import faqs from '@/config/pagesSettings/faqsPageSettings'
import homeSettings from '@/config/pagesSettings/homePageSettings'
import notificationsSettings from '@/config/pagesSettings/notificationsPageSettings'
import recordsSettings from '@/config/pagesSettings/recordsPageSettings'
import certificatesPageSettings from '@/config/pagesSettings/reportsSettings/certificatesPageSettings'
import instantSearchEventReportsSettings from '@/config/pagesSettings/reportsSettings/instantSearchEventReportsPageSettings'
import seriesReportSettings from '@/config/pagesSettings/reportsSettings/seriesReportPageSettings'
import weeklyViewingReportSettings from '@/config/pagesSettings/reportsSettings/weeklyViewingReportPageSettings'
import roadmapSettings from '@/config/pagesSettings/roadmapPageSettings'
import relativeDates, {
  conviva2RelativeDates,
  futureRelativeDates,
} from '@/config/relativeDates'
import routes from '@/config/routes'
import {
  AppSettingsI,
  CertificatesPageSettingsI,
  ClientSettingsI,
  ContactPageSettingsI,
  FaqSettingsI,
  HomeSettingsI,
  InstantSearchSettingsI,
  NotificationsSettingsI,
  ReportSettingsI,
  RoadmapSettingsI,
  SeriesReportsSettingsI,
  WeeklyViewingReportSettingsI,
} from '@/config/types'

import { useClientStore } from '../stores/client.store'

const useSettings = (): {
  appSettings: AppSettingsI
  clientSettings: ClientSettingsI
  faqs: FaqSettingsI
  routes: RouteObject[]
  navigation: any
  roadmap: RoadmapSettingsI
  contactPageSettings: ContactPageSettingsI
  homeSettings: HomeSettingsI
  notificationsSettings: NotificationsSettingsI
  seriesReportSettings: SeriesReportsSettingsI
  instantSearchEventReportsSettings: InstantSearchSettingsI
  minutesUniquesSettings: any
  minutesUniquesRealtimeSettings: any
  weeklyViewingReportSettings: WeeklyViewingReportSettingsI
  recordsSettings: any
  relativeDates: RelativeDateI[]
  futureRelativeDates: RelativeDateI[]
  conviva2RelativeDates: RelativeDateI[]
  certificatesPageSettings: CertificatesPageSettingsI
} => {
  const client = useClientStore((state) => state.client)

  return {
    appSettings: appSettings,
    clientSettings: clientSettings[client],
    faqs: faqs[client],
    routes: routes[client],
    navigation: navigation(),
    roadmap: roadmapSettings[client],
    contactPageSettings: contactPageSettings[client],
    homeSettings: homeSettings[client],
    notificationsSettings: notificationsSettings[client],
    seriesReportSettings: seriesReportSettings[client],
    instantSearchEventReportsSettings:
      instantSearchEventReportsSettings[client],
    weeklyViewingReportSettings: weeklyViewingReportSettings[client],
    minutesUniquesSettings: minutesUniquesSettings[client],
    minutesUniquesRealtimeSettings: minutesUniquesRealtimeSettings[client],
    recordsSettings: recordsSettings[client],
    relativeDates: relativeDates[client],
    futureRelativeDates: futureRelativeDates[client],
    conviva2RelativeDates: conviva2RelativeDates[client],
    certificatesPageSettings: certificatesPageSettings[client],
  }
}

export default useSettings
