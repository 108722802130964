import { ClientT, InstantSearchSettingsI } from '@/config/types'

import nbcuDtcInstantSearchEventReportSettings from './nbcu-dtc'
import nowInstantSearchEventReportSettings from './now'
import peacockInstantSearchEventReportSettings from './peacock'
import skyEuropeInstantSearchEventReportSettings from './sky-europe'
import skyEuropeAndPeacockInstantSearchEventReportSettings from './sky-europe-and-peacock'
import skyshowtimeInstantSearchEventReportSettings from './skyshowtime'

export const instantSearchEventReportSettings: Record<
  ClientT,
  InstantSearchSettingsI
> = {
  peacock: peacockInstantSearchEventReportSettings,
  skyshowtime: skyshowtimeInstantSearchEventReportSettings,
  now: nowInstantSearchEventReportSettings,
  'nbcu-dtc': nbcuDtcInstantSearchEventReportSettings,
  'sky-europe-and-peacock': skyEuropeAndPeacockInstantSearchEventReportSettings,
  'sky-europe': skyEuropeInstantSearchEventReportSettings,
  showmax: skyEuropeInstantSearchEventReportSettings, // TODO: Update me
}

export default instantSearchEventReportSettings
