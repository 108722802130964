import { ClientT, ContactPageSettingsI } from '@/config/types'

// As clients become more varied, start creating Contact Settings unique to each client
import commonContactSettings from './common'

export const contactPageSettings: Record<ClientT, ContactPageSettingsI> = {
  'nbcu-dtc': commonContactSettings,
  now: commonContactSettings,
  peacock: commonContactSettings,
  showmax: commonContactSettings,
  'sky-europe-and-peacock': commonContactSettings,
  'sky-europe': commonContactSettings,
  skyshowtime: commonContactSettings,
}

export default contactPageSettings
