import { ClientT } from '@/config/types'

import skyEuropeAndPeacockRecordsPageSettings from './sky-europe-and-peacock'

export const recordsPage: Record<ClientT, any> = {
  peacock: skyEuropeAndPeacockRecordsPageSettings, // TODO: Update me
  skyshowtime: skyEuropeAndPeacockRecordsPageSettings, // TODO: Update me
  now: skyEuropeAndPeacockRecordsPageSettings, // TODO: Update me
  'nbcu-dtc': skyEuropeAndPeacockRecordsPageSettings, // TODO: Update me
  'sky-europe-and-peacock': skyEuropeAndPeacockRecordsPageSettings,
  'sky-europe': skyEuropeAndPeacockRecordsPageSettings, // TODO: Update me
  showmax: skyEuropeAndPeacockRecordsPageSettings, // TODO: Update me
}

export default recordsPage
