import { CertificatesPageSettingsI, ClientT } from '@/config/types'

import peacockCertificatesPageSettings from './peacock'

export const certificatesPageSettings: Record<
  ClientT,
  CertificatesPageSettingsI
> = {
  peacock: peacockCertificatesPageSettings,
  skyshowtime: peacockCertificatesPageSettings, // Not used
  now: peacockCertificatesPageSettings, // Not used
  'nbcu-dtc': peacockCertificatesPageSettings, // Not used
  'sky-europe-and-peacock': peacockCertificatesPageSettings, // Not used
  'sky-europe': peacockCertificatesPageSettings, // Not used
  showmax: peacockCertificatesPageSettings, // Not used
}

export default certificatesPageSettings
